import {Layout} from "antd";
import {FormattedMessage} from "react-intl";

const {Footer} = Layout;

// function getLanguage() {
//     let varLanguage = 'en'
//     if (localStorage.getItem("language") === undefined) {
//         const lang = window.navigator.language;
//         if (lang && lang.startsWith('es')) {
//             varLanguage = 'es'
//         }
//     } else if (localStorage.getItem("language") === 'es') {
//         varLanguage = 'es'
//     }
//     return varLanguage;
// }

const Home = (props) => {

    // const REACT_APP_NAME = process.env.REACT_APP_NAME;
    // const REACT_APP_TEXT_A1 = getLanguage() === 'es' ? process.env.REACT_APP_TEXT_A1_ES : process.env.REACT_APP_TEXT_A1;
    // const REACT_APP_TEXT_A2 = getLanguage() === 'es' ? process.env.REACT_APP_TEXT_A2_ES : process.env.REACT_APP_TEXT_A2;
    // const REACT_APP_TEXT_B1 = getLanguage() === 'es' ? process.env.REACT_APP_TEXT_B1_ES : process.env.REACT_APP_TEXT_B1;

    return (
        <Layout>
            <link rel="stylesheet" href="./configurable/contents/css/bootstrap.min.css"/>
            <link rel="stylesheet" href="./configurable/contents/css/styles.css"/>
            {/*<!--  =============    Banner Section  ==========    -->*/}
            <div className="bannersSection">
                {/*<!-- <div style={{paddingLeft: '1vw', paddingTop: '1vw', mixBlendMode: 'multiply'}}>
                    <img src="./configurable/contents/images/logo_blue.png" alt="banner logo" className="responsive"
                         style={{width: '7%', height: 'auto'}}/>
                </div>  -->*/}

                <div className="text-container">
                    <h1>MyDatAnalysisDemo </h1>
                    <h2><br />VectorAnalytica Commercial Platform - Demo version</h2>
                    <p></p>
                </div>



            </div>

            <div className="containersSection">

                <section className="mainDescriptionContainer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="section-title">Get started with MyDatAnalysis Demo</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 text">
                                <div>
                                    <p> <br /></p>
                                    <p>
                                        Welcome to VectorAnalytica Demo platform, your gateway to advanced data collection, analysis, and reporting. This platform showcases the power of our technology with two basic sample forms and a series of pre-configured analyses, highlighting the capabilities you can leverage for your business.
                                    </p>
                                    <p>
                                        Unlock the full potential of your data with the same robust features available in the basic or premium version of our commercial platform. Visit our <a target="_blank" rel="noreferrer"
                                                                                                                                                                                                href="https://www.vectoranalytica.com/products">product page </a> to learn more and see how VectorAnalytica can transform your data into actionable insights, driving smarter decisions and greater efficiency.
                                    </p>
                                    <p>
                                        For questions, comments or other reason <a target="_blank" rel="noreferrer"
                                                                                   href="https://www.vectoranalytica.com/contact-us">contact-us </a>.
                                    </p>

                                </div>
                            </div>

                            <div className="col-lg-6 image">
                                <img src="./configurable/contents/images/image_A.png" alt="workflow" width="auto" height="350px"/>
                            </div>
                        </div>

                    </div>
                </section>

                <section id="presentation">

                    <div className="container dark">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="section-title">
                                    Real Time data collection with MyQueryForm and MyDatAnalysis Demo </h2>
                            </div>

                            <div className="col-md-12">
                                <p>
                                    Designed with an intuitive Drag and Drop interface, our demo provides a glimpse into the seamless data integration and analysis you can achieve. While the demo interface is locked to maintain the integrity of the analyses, you’ll experience firsthand how easy it is to enter data through our mobile app and see immediate results in the data table.
                                </p>
                                <p>
                                    Explore our interactive analysis menu, apply various filters based on predefined variables, and discover insightful visualizations. VectorAnalytica Demo empowers you to customize analyses fully, incorporating multiple data sources to calculate indices and other crucial measures tailored to your industry and specific needs.

                                </p>
                            </div>

                            <div className="col-md-12 video-responsive" align="center">
                                <img src="./configurable/contents/images/image_B.png" alt="image_B.png" width="auto" height="600px"/>
                            </div>
                        </div>
                    </div>
                </section>



                {/*<!--  =============    MyQueryForm presentation   ==========    -->*/}

                <section id="register-organization">
                    <div className="container dark">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="section-title">Start with MyQueryForm Mobile App</h2>
                                <div className="col-md-12">
                                    <p>
                                        Collect data on the go! <b>MyQueryForm</b> syncs seamlessly with <b>MyDatAnalysis</b>, allowing field staff to gather information even
                                        without an internet connection.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 image">
                                <img src="./configurable/contents/images/image_C.png" alt="MyQueryForm" />
                            </div>

                            <div className="col-lg-6 text">
                                <p>
                                    <b>To begin:</b>
                                </p>
                                <ol>
                                    <li>Download <b>MyQueryForm</b> app from <a target="_blank" rel="noreferrer"
                                                                                href="https://apps.apple.com/us/app/myqueryform/id1361882359">Apple
                                        Store</a> or
                                        from <a target="_blank" rel="noreferrer"
                                                href="https://play.google.com/store/apps/details?id=com.va.myqueryformnew">Google
                                            Play Store</a>.
                                    </li>
                                    <li>Open <b>MyQueryForm</b> and set the URL exactly as <a target="_blank" rel="noreferrer" href="https://mydatanalysisdemo.vectoranalytica.com">https://mydatanalysisdemo.vectoranalytica.com</a></li>
                                    <li><FormattedMessage id="label.home_h9" defaultMessage="Username: "/><span
                                        className="text-danger">user2023</span>
                                    </li>
                                    <li><FormattedMessage id="label.home_h10" defaultMessage="Password: "/><span
                                        className="text-danger">user2023@</span>
                                    </li>
                                    <li>Tap FORMS button and select a sample query from the list.
                                    </li>
                                    <li>Return to MAPS and tap red location marker to open selected form
                                    </li>
                                    <li>Complete form and SEND
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="mainFeaturesSection">
                    <div className="container">
                        <h2 className="section-title">Why Choose MyDatAnalysis?<br/></h2>

                        <hr style={{border: '1px solid #1D46D9'}}/>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 mainFeatureArticle">
                                <img src="./configurable/contents/images/universal.svg" alt="Universal Accessibility"/>
                                <div className="textRow">
                                    <h3 className="section-title"><b>Universal Accessibility</b></h3>
                                    <div>
                                        Whether you’re in a bustling metropolis or a remote village, MyDatAnalysis
                                        is at
                                        your fingertips. Our web and mobile integration ensures seamless data
                                        collection, even
                                        in areas with limited connectivity.
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mainFeatureArticle">
                                <img src="./configurable/contents/images/multiplatform.svg"
                                     alt="Universal Accessibility"/>
                                <div className="textRow">
                                    <h3 className="section-title"><b>Multiplatform</b></h3>
                                    <div>
                                        Web-Based and mobile device applications can run on UNIX, Windows or Mac OS.
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mainFeatureArticle">
                                <img src="./configurable/contents/images/userfriendly.svg"
                                     alt="User-Friendly Interface"/>
                                <div className="textRow">
                                    <h3 className="section-title"><b>User-Friendly Interface</b></h3>
                                    <div>
                                        No coding or specialized skills required! Create custom surveys effortlessly
                                        using our
                                        intuitive drag-and-drop interface. Design forms tailored to your research needs
                                        and
                                        deploy them instantly.
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mainFeatureArticle">
                                <img src="./configurable/contents/images/georeferenced.svg" alt="Georeferenced Data"/>
                                <div className="textRow">
                                    <h3 className="section-title"><b>Georeferenced Data</b></h3>
                                    <div>
                                        Visualize your data geographically! MyDatAnalysis enables registered users
                                        to
                                        display and export georeferenced data. Pinpoint trends, hotspots, and patterns
                                        with
                                        ease.
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mainFeatureArticle">
                                <img src="./configurable/contents/images/preconfigured.svg"
                                     alt="Preconfigured Analysis"/>
                                <div className="textRow">
                                    <h3 className="section-title"><b>Preconfigured Analysis</b></h3>
                                    <div>
                                        Unlock insights instantly! Explore preconfigured analyses such as Time Series,
                                        Bar
                                        Plots, and Point Maps. Make informed decisions based on real-time data.
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mainFeatureArticle">
                                <img src="./configurable/contents/images/secure.svg" alt="Secure"/>
                                <div className="textRow">
                                    <h3 className="section-title"><b>Secure</b></h3>
                                    <div>
                                        User access is restricted to registered users only, controlled by user-role
                                        permissions
                                        for secure cloud storage. Each user and their project's staff members have access
                                        only to
                                        their project data and analysis.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="mainBrowserSupportSection">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="section-title">Browsers Supported</h2>
                                <hr style={{border: '1px solid #1D46D9'}}/>
                            </div>
                            <div className="col-md-4">
                                <img src="./configurable/contents/images/browser_logos.png" alt="browser_logos.png"
                                     width="100%"
                                     height="auto"/>
                            </div>
                            <div className="col-md-8 text">
                                <p>
                                    Platform tested and supported on major browsers like Chrome, Firefox, Internet
                                    Explorer,
                                    Microsoft Edge, Opera, and Safari for iOS.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()} <FormattedMessage id="label.home_h29"
                                                                                                defaultMessage="Powered By Vector Analytica,Inc"/></Footer>
        </Layout>
    );
};

export default Home;
