import {Form, Input, Select, message, Breadcrumb, Layout, Card, Button, Radio} from 'antd';
import {FormattedMessage, useIntl} from "react-intl";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    setLoading,
    clearLoading,
    addUser,
    getUser,
    editUser,
    clearUserError
} from "../../redux/user/userActions";
import {HomeOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {selectError, selectLoading, selectCurrentUser, selectGeoRegions} from "../../redux/user/userSelector";
import {listRoles} from "../../redux/role/roleActions";
import {selectRoles} from "../../redux/role/roleSelector";
import {getUserRegions} from "../../utils/utilData";

const {Content, Footer} = Layout;


const UserForm = (props) => {

    const intl = useIntl();
    const dispatch = useDispatch();

    const [userCreated, setUserCreated] = useState(false);
    const [edit, setEdit] = useState(props.location.pathname !== '/admin/users/new');

    const [form] = Form.useForm();

    const onFinish = (values) => {
        if (edit) {
            props.editUser(props.history, props.match.params.id, values, setUserCreated);
        } else {
            props.addUser(props.history, values, setUserCreated);
        }

    };

    const onFinishFailed = (errorInfo) => {
        errorInfo.errorFields.map(error => (message.error(error.errors[0])));
    };

    const [roleList, setRoleList] = useState([{label: intl.formatMessage({id: 'label.select'}), value: null}])
    const [geoRegionsList, setGeoRegionsList] = useState([{
        label: intl.formatMessage({id: 'label.select'}),
        value: null
    }])

    useEffect(() => {
        if (roleList.length === 1) {
            dispatch(setLoading());
            if (props.role === null || props.role.length === 0) {
                dispatch(getUserRegions());
                let pagination = {}
                pagination['current'] = 1;
                pagination['pageSize'] = 9999999;
                let sorter = {}
                sorter['field'] = 'authority';
                dispatch(listRoles(props.history, pagination, {}, sorter));
            } else {
                const loadedRoles = [...roleList];
                props.role?.roles?.map(role => loadedRoles.push({label: role.authority, value: role.id}))
                setRoleList(loadedRoles);

                if (props.geoRegions !== undefined && geoRegionsList.length === 1) {
                    const loadedRegions = [...geoRegionsList];
                    props.geoRegions?.geoRegions.map(geoRegion => loadedRegions.push({
                            label: geoRegion.name,
                            value: geoRegion.regionCode
                        })
                    )
                    setGeoRegionsList(loadedRegions);
                }
                dispatch(clearLoading());
            }
        }
        if (props.error) {
            const error = () => {
                if (typeof props.error == "string") {
                    message.error(props.error).then(props.clearUserError());
                } else if (props.error.hasOwnProperty('message')) {
                    message.error(props.error.message).then(props.clearFormDataError());
                } else {
                    props.error.errors.map(errorMessage =>
                        (message.error(errorMessage.message).then(props.clearUserError())));
                }

            };
            error();
        }

        if (edit && !userCreated && props.error === null) {
            props.getUser(props.history, props.match.params.id).then((data) => {
                if (data) {
                    form.setFieldsValue({
                        'firstName': data.firstName,
                        'lastName': data.lastName,
                        'email': data.email,
                        'phone': data.phone,
                        'area': data.area,
                        'job': data.job,
                        'company': data.company,
                        'role': data.role,
                        'enabled': data.enabled
                    });
                } else {
                    setEdit(false);
                }
            })
        }

        if (userCreated) {
            if (edit) {
                message.info(intl.formatMessage({id: 'label.user-updated'}));
            } else {
                form.resetFields();
                message.info(intl.formatMessage({id: 'label.user-created'}));
            }
            setUserCreated(false);
        }
        // eslint-disable-next-line
    }, [props.error, userCreated, edit, props.role])

    return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="label.user" defaultMessage="User"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span>{edit ? (<FormattedMessage
                            id="label.edit" defaultMessage="Edit"/>) : (
                            <FormattedMessage id="label.new" defaultMessage="New"/>)}</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <Card type="inner"
                          title={edit ? <FormattedMessage id="label.edit-user" defaultMessage="Edit User"/> :
                              <FormattedMessage id="label.create-user" defaultMessage="Add User"/>}
                          extra={<Link to='/admin/users'><FormattedMessage id="label.return"
                                                                           defaultMessage="Return"/></Link>}>
                        <Form layout="horizontal" name="user_form" size="large"
                              labelCol={{span: 3,}} wrapperCol={{span: 14,}}
                              initialValues={{active: true}} form={form}
                              onFinish={onFinish} onFinishFailed={onFinishFailed}>
                            <Form.Item
                                name="firstName"
                                label={intl.formatMessage({id: 'label.first-name'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}

                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name="lastName"
                                label={intl.formatMessage({id: 'label.last-name'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}

                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name="email"
                                label={intl.formatMessage({id: 'label.email'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}

                            >
                                <Input type='email'/>
                            </Form.Item>
                            <Form.Item name="area" label={intl.formatMessage({id: 'label.region'})}
                                       rules={[{
                                           required: true,
                                           message: intl.formatMessage({id: 'msg.input-required'})
                                       }]}>
                                <Select options={geoRegionsList}>

                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="job"
                                label={intl.formatMessage({id: 'label.job'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}

                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name="company"
                                label={intl.formatMessage({id: 'label.company'})}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name="phone"
                                label={intl.formatMessage({id: 'label.phone'})}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item name="role" label={intl.formatMessage({id: 'label.role'})}
                                       rules={[{
                                           required: true,
                                           message: intl.formatMessage({id: 'msg.input-required'})
                                       }]}>
                                <Select options={roleList}>

                                </Select>
                            </Form.Item>
                            <Form.Item name="enabled" label={intl.formatMessage({id: 'label.enabled'})} rules={[{
                                required: true,
                                message: intl.formatMessage({id: 'msg.input-required'})
                            }]}>
                                <Radio.Group>
                                    <Radio value={true}><FormattedMessage id="label.yes" defaultMessage="Yes"/></Radio>
                                    <Radio value={false}><FormattedMessage id="label.no" defaultMessage="No"/></Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={props.loading}>
                                    {edit ? (<FormattedMessage id="label.update" defaultMessage="Update"/>) : (
                                        <FormattedMessage id="label.submit" defaultMessage="Submit"/>)}

                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
};

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    user: selectCurrentUser(state),
    role: selectRoles(state),
    geoRegions: selectGeoRegions(state)
});

export default connect(mapStateToProps, {addUser, getUser, editUser, clearUserError})(UserForm);